import { Box, Group, Image, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { ErrorComponent, ErrorComponentProps, getRouteApi } from '@tanstack/react-router';
import { dogParkLeaderboardOptions, LEADERBOARD_ENTRIES_LIMIT, LeaderboardData } from './DogParkLeaderboard.query';
import { AxiosError } from 'axios';
import { DogParkLeaderboardEntry } from '../DogParkLeaderboardEntry/DogParkLeaderboardEntry';
import { DogParkLeaderboardEmptyEntry } from '../DogParkLeaderboardEmptyEntry/DogParkLeaderboardEmptyEntry';
import { Carousel } from '@mantine/carousel';
import { useContext, useEffect, useRef, useState } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { QRCode } from 'react-qrcode-logo';
import classes from './DogParkLeaderboard.module.css';

import FiLogo from '../../assets/fi_logo_yellow.svg';
import LeaderboardRankingDog from '../../assets/leaderboard_ranking_dog.mp4';
import CountdownCircle from './CountdownCircle';
import { DogParkScreenContext } from '../DogParkScreen/DogParkScreenContext';

const route = getRouteApi('/dogpark/leaderboard/$id');

interface Testimonial {
  quote: string;
  attribution: string;
}

function getCodeUrl() {
  // TODO: update this with utm query params for tracking and coupon(?)
  // https://app.asana.com/0/1207101507783044/1207679714515266/f
  return 'https://tryfi.com';
}

export function ConnectedDogParkLeaderboard() {
  const { id } = route.useParams();
  const { error, data, isLoading } = useQuery(dogParkLeaderboardOptions(id));

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error! {error.message}</Text>;
  }

  if (!data) {
    return <Text>Response data empty</Text>;
  }

  return <DogParkLeaderboard data={data} />;
}

export function DogParkLeaderboard({ data }: { data: LeaderboardData }) {
  const autoplay = useRef(Autoplay({ delay: 4000 }));
  useAutoReload(10);

  const dogParkScreenContext = useContext(DogParkScreenContext); // Optionally retrieve context for countdown

  // break ties with step counts
  const sortedRanks = [...data.leaderboard].sort((a, b) =>
    b.visitCount === a.visitCount ? b.stepsThisMonth - a.stepsThisMonth : b.visitCount - a.visitCount,
  );

  const leaderboardEntries = sortedRanks.map((entry, index) => {
    return <DogParkLeaderboardEntry entryData={entry} rank={index + 1} key={index} />;
  });

  // if the leaderboard is not full, add empty entries to fill it
  if (leaderboardEntries.length < LEADERBOARD_ENTRIES_LIMIT) {
    for (let i = leaderboardEntries.length; i < LEADERBOARD_ENTRIES_LIMIT; i++) {
      leaderboardEntries.push(<DogParkLeaderboardEmptyEntry rank={i + 1} key={i} />);
    }
  }

  const testimonials: Testimonial[] = [
    { quote: '“The peace of mind I get is worth every penny”', attribution: '@myheartbeatsatmyfeet' },
    { quote: '“I don’t worry about losing any of my six dogs now”', attribution: '@jeremygranthorton' },
    { quote: '“Helped me get my escape artist back!”', attribution: 'Rachel D.' },
    { quote: '“The best decision ever buying this GPS collar!!”', attribution: '@shirley2792' },
  ];

  const testimonialSlides = testimonials.map((t, i) => (
    <Carousel.Slide key={i}>
      <Stack className={classes.slide}>
        <Text className={classes.quoteText}>{t.quote}</Text>
        <Text className={classes.attributionText}>{t.attribution}</Text>
      </Stack>
    </Carousel.Slide>
  ));

  // Adjust content of the page to match the scale of the page
  // This reduces the vertical and horizontal padding around the content
  const scale = useScale();

  // Hide scroll bars when the page is scaled
  document.body.style.overflow = 'hidden';

  return (
    <Stack
      justify="center"
      align="stretch"
      bg="background-dark-primary"
      h="100vh"
      style={{
        transform: `scale(${scale})`,
      }}
    >
      <Group wrap="nowrap" justify="center" align="center" gap="40px">
        <Stack className={classes.leaderboardContainer}>
          <Group wrap="nowrap" align="flex-start">
            <Image src={FiLogo} bg="fi-yellow" radius="sm" />
            <Title className={classes.leaderboardTitle}>Top Dogs at {data.name}</Title>
          </Group>
          <Text className={classes.leaderboardSubtitle}>Get a Fi collar to join the chase!</Text>
          <SimpleGrid cols={2} spacing="16px">
            <Stack gap="16px">{leaderboardEntries.slice(0, 3)}</Stack>
            <Stack gap="16px">{leaderboardEntries.slice(3)}</Stack>
          </SimpleGrid>
          <Group align="center" justify="space-between" style={{ width: '100%' }}>
            <Text c="type-dark-secondary" fz="12px" fw={500} lh="140%" mt={dogParkScreenContext ? '-5%' : '0%'}>
              Dogs with the most visits over the last 28 days.
            </Text>
            {dogParkScreenContext && <CountdownCircle progress={dogParkScreenContext.countdown / 60} />}
          </Group>
        </Stack>
        <Stack align="center" className={classes.marketingSidebar}>
          <video autoPlay muted loop className={classes.videoBackground}>
            <source src={LeaderboardRankingDog} type="video/mp4" />
          </video>
          <Title className={classes.marketingTitle}>Meet Series 3 – Live GPS, health and behavior tracking</Title>
          <Box maw="100%" mt="auto">
            <Carousel
              loop
              draggable={false}
              withControls={false}
              slideSize="256px"
              slideGap="8px"
              plugins={[autoplay.current]}
            >
              {testimonialSlides}
            </Carousel>
          </Box>
          <Group className={classes.marketingFooter} wrap="nowrap">
            <QRCode size={56} value={getCodeUrl()} qrStyle="dots" style={{ borderRadius: '6px' }} />
            <Stack gap="0px">
              <Text className={classes.promoText}>Visit tryfi.com</Text>
              <Text className={classes.promoText}>to learn more</Text>
            </Stack>
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
}

export function DogParkLeaderboardError(props: ErrorComponentProps) {
  const { error } = props;
  if (error instanceof AxiosError) {
    return (
      <Box>
        <Title>Uh-oh!</Title>
        <Text>Something went wrong!</Text>
        <Text>Error: {error.message}</Text>
      </Box>
    );
  }
  return <ErrorComponent error={error} />;
}

function useAutoReload(minutes: number) {
  useEffect(() => {
    const interval = setInterval(
      () => {
        window.location.reload();
      },
      minutes * 60 * 1000,
    );

    return () => clearInterval(interval);
  }, [minutes]);
}

function useScale() {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const scaleFactor = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
      setScale(scaleFactor * 1.35);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return scale;
}
