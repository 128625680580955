import { createFileRoute } from '@tanstack/react-router';
import {
  ConnectedDogParkLeaderboard,
  DogParkLeaderboardError,
} from '../components/DogParkLeaderboard/DogParkLeaderboard';
import { captureException } from '@sentry/react';
import { dogParkLeaderboardOptions } from '../components/DogParkLeaderboard/DogParkLeaderboard.query';
import { DogParkScreen } from '../components/DogParkScreen/DogParkScreen';
import { useSearch } from '@tanstack/react-router';
import { DogParkScreenContextProvider } from '../components/DogParkScreen/DogParkScreenContext';

const DogParkRouteComponent = () => {
  const search = useSearch({ from: '/dogpark/leaderboard/$id' });

  if (search?.dynamic === 'true') {
    return (
      <DogParkScreenContextProvider>
        <DogParkScreen />
      </DogParkScreenContextProvider>
    );
  } else {
    return <ConnectedDogParkLeaderboard />;
  }
};

export const Route = createFileRoute('/dogpark/leaderboard/$id')({
  component: DogParkRouteComponent,
  onError: ({ error }) => {
    captureException(error);
  },
  errorComponent: DogParkLeaderboardError,

  validateSearch: (search: string | Record<string, string> | string[][] | URLSearchParams | undefined) => {
    const params = new URLSearchParams(search as string);
    const dynamic = params.get('dynamic');
    return { dynamic };
  },

  loader: ({ context: { queryClient }, params }) => {
    return queryClient.ensureQueryData(dogParkLeaderboardOptions(params.id));
  },
});
