import './DogParkVideo.css';
import { useDogParkScreenContext } from '../DogParkScreen/useDogParkScreenContext';
import { useState } from 'react';
import CountdownCircle from '../DogParkLeaderboard/CountdownCircle';
import { QRCode } from 'react-qrcode-logo';
import FiLogo from '../../assets/fi_logo_yellow.svg';
import { Box, Text } from '@mantine/core';

const VideoPlayer = () => {
  const { videoData, countdown, videoDurationSeconds } = useDogParkScreenContext();
  const { currentVideoUrl, currentFiHandle, handleVideoEnd } = useVideoSequence(videoData);

  return (
    <div className="video-container">
      <video className="video-background" src={currentVideoUrl} autoPlay muted onEnded={handleVideoEnd} />
      <video className="video-foreground" src={currentVideoUrl} autoPlay muted onEnded={handleVideoEnd} />
      <div className="video-overlay">
        <div className="countdown-container">
          <CountdownCircle progress={countdown / videoDurationSeconds} />
        </div>
        <div className="qr-code">
          <QRCode value={'https://tryfi.com'} qrStyle="dots" style={{ borderRadius: '6px' }} />
        </div>
        <div className="fi-logo" style={{ transform: 'scale(1.5)' }}>
          <img src={FiLogo} alt="Fi Logo" />
        </div>
        <div className="user-handle">
          <Box>
            <Text c="black">@{currentFiHandle}</Text>
          </Box>
        </div>
      </div>
    </div>
  );
};

const useVideoSequence = (videoData: Record<string, any>) => {
  const videoIds = Object.keys(videoData);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const currentVideoId = videoIds[currentVideoIndex];
  const currentVideoUrl = videoData[currentVideoId].videoObjectUrl;
  const currentFiHandle = videoData[currentVideoId].fiHandle;

  const handleVideoEnd = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoIds.length;
    setCurrentVideoIndex(nextVideoIndex);
  };

  return { currentVideoUrl, currentFiHandle, handleVideoEnd };
};

export default VideoPlayer;
