import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { publicCommunityPostOptions } from '../../utils/publicPost';
import { DOG_VIDEOS } from '../DogParkVideo/DogParkVideos';
import { captureException } from '@sentry/react';

const downloadVideo = async (mp4Url: string) => {
  const response = await fetch(mp4Url);

  if (!response.ok) {
    throw new Error('Failed to fetch video');
  }

  const videoBlob = await response.blob();
  const videoObjectUrl = URL.createObjectURL(videoBlob);

  const videoDuration = await getVideoDuration(videoObjectUrl);

  return { videoObjectUrl, videoDuration };
};

const clearVideos = (videoData: Record<string, any>) => {
  for (const videoUrl of Object.values(videoData)) {
    URL.revokeObjectURL(videoUrl);
  }
};

const getVideoDuration = async (mp4Url: string): Promise<number> => {
  const videoElement = document.createElement('video');
  videoElement.src = mp4Url;

  return new Promise((resolve, reject) => {
    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration);
    };

    videoElement.onerror = () => {
      reject(new Error('Failed to load video metadata'));
    };
  });
};

export const useVideoLoader = () => {
  const [videoData, setVideoData] = useState<Record<string, any>>({});
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [dogVideoIndex, setDogVideoIndex] = useState<number>(0);
  const [videoDurationSeconds, setVideoDurationSeconds] = useState(0);

  const queryClient = useQueryClient();

  // Given a target videoSeconds, retrieves videos from a preset list of dog video posts,
  // downloads them in order, and sets the videoData state.
  const processVideos = async (videoSeconds: number) => {
    let totalDurationSeconds = 0;
    let index = dogVideoIndex;
    let data;
    let videoDurationSeconds: number;
    let videoObjectUrl: string;

    while (totalDurationSeconds < videoSeconds) {
      const videoId = DOG_VIDEOS[index];

      try {
        data = await queryClient.fetchQuery(publicCommunityPostOptions(videoId));
        if (data.post.type !== 'video' || !data.post.mp4Url) {
          throw new Error('Invalid post');
        }

        const videoData = await downloadVideo(data.post.mp4Url);
        videoDurationSeconds = videoData.videoDuration;
        videoObjectUrl = videoData.videoObjectUrl;
      } catch (error) {
        captureException(error);
        index++;
        continue;
      }

      totalDurationSeconds += videoDurationSeconds;

      const fiHandle = data.pet.fiHandle;

      setVideoData((prevData) => ({ ...prevData, [videoId]: { videoObjectUrl: videoObjectUrl, fiHandle: fiHandle } }));

      index++;
    }

    return { index, totalDurationSeconds };
  };

  const loadVideos = async (videoSeconds: number = 180) => {
    setIsAllLoaded(false);
    clearVideos(videoData);
    setVideoData({});

    const { index: nextIndex, totalDurationSeconds } = await processVideos(videoSeconds);

    // Countdown timer isn't exact, manual adjustment to make sure countdown ends before video ends
    setVideoDurationSeconds(Math.max(0, Math.floor(totalDurationSeconds) - 2));
    setDogVideoIndex(nextIndex);
    setIsAllLoaded(true);
  };

  return {
    loadVideos,
    videoData,
    isAllLoaded,
    videoDurationSeconds,
  };
};
