import { QRCode } from 'react-qrcode-logo';
import classes from './DogParkJoinCTA.module.css';
import { InstagramCarousel } from './InstagramCarousel';

const DogParkJoinCTA = () => {
  return (
    <div className={classes.container}>
      <div className={classes.textSection}>
        <h1 className={classes.heading}>Want to see your dog up here? Join the Fi community</h1>
        <div className={classes.qrCode}>
          <QRCode value={'https://tryfi.com'} qrStyle="dots" style={{ borderRadius: '6px' }} />
        </div>
      </div>
      <div className={classes.imageSection}>
        <InstagramCarousel />
      </div>
    </div>
  );
};

export default DogParkJoinCTA;
