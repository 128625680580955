import { RingProgress } from '@mantine/core';

const CountdownCircle = ({ progress }: { progress: number }) => {
  const size = 100;

  return (
    <RingProgress
      size={size}
      thickness={10}
      sections={[{ value: progress * size, color: 'fi-yellow' }]}
      rootColor="black"
    />
  );
};

export default CountdownCircle;
