import { useContext } from 'react';
import { DogParkScreenContext } from './DogParkScreenContext';

export const useDogParkScreenContext = () => {
  const context = useContext(DogParkScreenContext);
  if (!context) {
    throw new Error('useDogParkScreenContext must be used within a DogParkScreenContextProvider');
  }
  return context;
};
