import React, { createContext } from 'react';
import { useVideoLoader } from './useVideoLoader'; // Import the custom hook
import { useState, useEffect } from 'react';
import { ConnectedDogParkLeaderboard } from '../DogParkLeaderboard/DogParkLeaderboard';
import DogParkVideo from '../DogParkVideo/DogParkVideo';
import DogParkJoinCTA from '../DogParkJoinCTA/DogParkJoinCTA';
interface DogParkScreenContextType {
  loadVideos: (videoSeconds: number) => Promise<void>;
  videoData: Record<string, any>;
  isAllLoaded: boolean;
  countdown: number;
  currentScreen: React.ReactNode;
  videoDurationSeconds: number;
}

export const DogParkScreenContext = createContext<DogParkScreenContextType | undefined>(undefined);

export const DogParkScreenContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loadVideos, videoData, isAllLoaded, videoDurationSeconds } = useVideoLoader();

  useEffect(() => {
    loadVideos(180);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [countdown, setCountdown] = useState(60); // Initial leaderboard transition time is 60 seconds

  useCountdown(countdown, setCountdown, async () => {
    const nextScreenIndex = (currentScreenIndex + 1) % subScreens.length;

    setCurrentScreenIndex(nextScreenIndex);

    switch (nextScreenIndex) {
      case 0: // Leaderboard screen
        await loadVideos(180); // Load videos for next screen
        setCountdown(60); // Next screen duration
        break;
      case 1: // Video screen
        setCountdown(videoDurationSeconds);
        break;
      case 2: // Join CTA screen
        setCountdown(20);
    }
  });

  const subScreens = [<ConnectedDogParkLeaderboard />, <DogParkVideo />, <DogParkJoinCTA />];

  const currentScreen = subScreens[currentScreenIndex];

  return (
    <DogParkScreenContext.Provider
      value={{ loadVideos, videoData, isAllLoaded, countdown, currentScreen, videoDurationSeconds }}
    >
      {children}
    </DogParkScreenContext.Provider>
  );
};

const useCountdown = (
  countdown: number,
  setCountdown: React.Dispatch<React.SetStateAction<number>>,
  onComplete: () => void,
) => {
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      onComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return countdown;
};
